<script setup lang="ts">
const { t } = useT();
const { data: appInit } = useAppInitData();

defineProps<{
	subTitle: string;
	title: string;
	coins: number;
	entries: number;
	image: string;
}>();

const emit = defineEmits<{ (event: "click"): void }>();

const handleClick = () => {
	emit("click");
};

const { durationLeft: seasonTimeLeft } = useCountdown({
	timestamp: appInit.value?.season?.finishedAt as string,
	formatToken: "DD[d] HH[h] mm[m]"
});
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 656,
			height: 514,
			src: '/nuxt-img/seasons/halloween/promo.webp',
			loading: 'lazy',
			quality: 100,
			alt: 'card-season'
		}"
		height="257px"
		@click="handleClick"
	>
		<template #top>
			<div class="block">
				<AText variant="ternopil" :modifiers="['normal', 'uppercase']" class="time">
					{{ t("Ends in") }} {{ seasonTimeLeft }}
				</AText>
				<AText variant="tanta" :modifiers="['bold']" class="title">
					{{ title }}
				</AText>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']" class="subtitle">
					{{ subTitle }}
				</AText>
				<div v-if="coins !== 0" class="card-count coins">
					<MPrizeFund variant="coins" icon="20/entries" :icon-size="28">
						<AText variant="tanzay" class="text-chittagong" :modifiers="['black']">
							{{ numberFormat(coins) }}
						</AText>
					</MPrizeFund>
				</div>
				<div v-if="entries !== 0" class="card-count entries">
					<MPrizeFund variant="entries" icon="20/entries" :icon-size="38">
						<AText variant="tanzay" class="text-cixi" :modifiers="['black']">
							{{ numberFormat(entries) }}
						</AText>
					</MPrizeFund>
				</div>
			</div>
		</template>
		<template #bottom>
			<AButton v-if="appInit?.season?.isAvailable" variant="primary" class="promo" data-tid="promo-season">
				<AText variant="toledo" :modifiers="['semibold', 'uppercase']">{{ t("Dive into") }}</AText>
			</AButton>
			<AButton v-else variant="primary" class="promo" data-tid="promo-season">
				<AText variant="toledo" :modifiers="['semibold', 'uppercase']">{{ t("Get Your Pass") }}</AText>
				<NuxtIcon name="20/ticket" class="icon-ticket" filled />
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-count {
	display: flex;
	align-items: center;
	gap: gutter(0.5);
}

button {
	width: 240px;
	height: 40px;
	gap: gutter(0.75);
}

.card {
	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
	}

	.time {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.title {
		display: block;
		min-height: 15px;
		margin-bottom: gutter(2);
	}
}

.icon-ticket {
	font-size: 24px;
	margin-left: gutter(1);
}
</style>
